/* responsive for home page */
.desktop-home-table-title {
  display: block;
}
.desktop-productcard {
  display: block;
}
.mobile-productcard {
  display: none;
}

.buydeal-btn {
    width: fit-content;
    /*  
    max-width: fit-content; */
    background: #ff9900;
    border: 1px solid #ff9900;
    color: white;
    padding: 6px 10px;
    margin-right: -12px;
    font-weight: 700;
    outline: none;
    border-radius: 6px;
    text-transform: capitalize;
    cursor: pointer;
    transition: 300ms;
    text-decoration: none;
    font-size: 13px;
    /* box-shadow: 3px 3px 5px 0px rgb(0 0 0 / 75%); */
  }
  

@media screen and (max-width: 768px) and (min-width:520px) {
  .desktop-home-table-title {
    display: none;
  }
  .desktop-productcard {
    display: none;
  }
  .remainingcoins-text{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

  .mobile-productcard {
    display: block !important;
    background-color: #fff;
    width: 95%;
    /* height: 30px; */
    margin: auto;
    /* margin-bottom: 10px; */
    margin-top: 10px;
    border-radius: 6px;
    padding: 10px;
  }

  .mobile-top-wrapper {
    display: flex;
    justify-content: space-between;
    width: 96%;
    padding: 1%;
    margin: auto;
    margin-bottom: 5px;

  }
  .mobile-storename-wrapper{
    width: 80%;
  }
  .mobile-button-wrapper{
    width: 20%;
  }
  .mobile-checkbox-container{
    display: flex;
}
  .mobile-checkbox-wrapper{
    margin-right: 10px;
    margin-top: 2px;

  }
 

  .mobile-product-wrapper {
    display: flex;
    justify-content: space-between;
    width: 96%;
    padding: 1%;
    margin: auto;
  }
  .mobile-productcard-tile {
    background-color: #edf5ff;
    padding: 5px;
    border-radius: 6px;
    margin: 5px;
    width: 25%;
  }
  .mobile-productcard-tile > h4 {
    /* font-size: 13px; */
    font-weight: 400;
  }
.mobile-productcard-tile{
    /* font-size: 13px; */
}
  .mobile-category-wrapper {
    font-weight: 400;
  }
}



@media screen and (max-width: 520px) and (min-width:320px) {
    .desktop-home-table-title {
      display: none;
    }
    .desktop-productcard {
      display: none;
    }
    .mobile-productcard {
      display: block !important;
      background-color: #fff;
      width: 95%;
      /* height: 30px; */
      margin: auto;
      /* margin-bottom: 10px; */
      margin-top: 10px;
      border-radius: 6px;
      padding: 10px;
    }
  
    .mobile-top-wrapper {
      display: flex;
      justify-content: space-between;
      width: 96%;
      padding: 1%;
      margin: auto;
      margin-bottom: 5px;
    }
  
    .mobile-storename-wrapper{
        width: 65%;
      }
      .mobile-button-wrapper{
        width: 35%;
      }

    .mobile-product-wrapper {
      display: flex;
      justify-content: space-between;
      width: 96%;
      padding: 1%;
      margin: auto;
    }
   
    .mobile-productcard-tile {
      background-color: #edf5ff;
      padding: 5px;
      border-radius: 6px;
      margin: 5px;
      font-size: 13px;
      width: 25%;
    }
 


    .mobile-productcard-tile > h4 {
      font-size: 13px;
      font-weight: 400;
    }
    .mobile-checkbox-container{
        display: flex;
    }
      .mobile-checkbox-wrapper{
        margin-right: 10px;
        margin-top: 2px;

      }
    .mobile-category-wrapper {
      font-weight: 400;
    }
  }
  @media screen and (max-width:420px) and (min-width:320px) {
    .mobile-productcard-list-3,.mobile-productcard-list-4{
        margin-top: 20px;
    }
    .productname{
        width: 190px;
    }
.mobile-productcard-roi{
    position: relative;
    top: 20px;
}
   .mobile-productcard-roi-h5{
        margin-top: 24px;
   } 
   .mobile-productcard-tile:first-child{
    width: 40%;
  }
  .mobile-productcard-tile:nth-child(2),.mobile-productcard-tile:nth-child(3){
  width: 18%;
  }
  .mobile-productcard-tile:nth-child(4){
    width: 24%;
  }

  }