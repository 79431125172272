.purchased-rewapmed-res-parent {
  width: 100%;
  display: none;
}
.purchased-rewamped-res-up,
.purchased-rewamped-res-down {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.purchased-rewamped-res-down {
  margin: 20px 0px;
}
.purchased-rewamped-box {
  width: calc(25% - 14px);
  background-color: #edf5ff;
  padding: 10px;
  border-radius: 10px;
}

/* resposnsive */

@media only screen and (max-width: 992px) and (min-width: 320px) {
  .purchased-header,
  .purchasedpage-res > table {
    display: none;
  }
  .purchased-rewapmed-res-parent {
    display: block;
  }
}
@media only screen and (max-width: 992px) and (min-width: 768px) {
}
@media only screen and (max-width: 767px) and (min-width: 520px) {
}
@media only screen and (max-width: 520px) and (min-width: 320px) {
  .purchased-rewamped-box > h4 {
    font-size: 12px;
  }
  .purchased-rewamped-box > h5 {
    font-size: 11px;
  }
  .purchased-rewamped-box {
    padding: 10px 5px;
  }
  .product-dealmp-checkbox {
    margin-left: inherit !important;
  }
  .product-dealmp-containter {
    padding-left: inherit !important;
  }
  .productname{
    width:200px;
  }
}
@media only screen and (max-width: 420px) and (min-width: 320px) {
  .purchased-rewamped-box {
    width: calc(25% - 5px);
  }
  .purchased-roi-res{
    position: relative;
    top:20px;
  }
  .productname{
    /* width:196px; */
    width: 175px;
  }
  .purchased-product-list-4 , .purchased-product-list-3{
    margin-top:20px;
  }
  .purchased-roi-res-title{
    margin-top: 24px;
  }
  .product-table{
    width:98%;
  }
  .wishlist-header{
    padding:20px;
  }
}
