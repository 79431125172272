.min-container{
    width: 95%;
    margin: 0 auto 15px auto;
    /* border: 1px solid black; */
    background: #ffffff;
border-radius: 10px;
}
/* .min-container-btn{
   padding: 13px 0;
} */
.edit-btn{
    /* display: flex; */
    width: 100%;
    /* justify-content: space-evenly; */
    /* align-items: center; */
    max-width: 80px;
    background: #fff;
    border:1px solid #FF9900;
    color: #FF9900;
    padding: 8px 16px;
    font-weight: 700;
    outline: none;
    border-radius: 6px;
    text-transform: capitalize; 
    cursor: pointer;
   /* margin-left: auto; */
}

.reset-btn{
    /* display: flex; */
    /* width: 100%; */
    /* justify-content: space-evenly; */
    /* align-items: center; */
    max-width: 150px;
    background: #FF9900;
    border:1px solid #FF9900;
    color: white;
    padding: 8px 16px;
    font-weight: 700;
    outline: none;
    border-radius: 6px;
    text-transform: capitalize; 
    cursor: pointer;
   margin-right: 10px;
}
.update--btn{
    max-width: 80px;
    background: #FF9900;
    border:1px solid #FF9900;
    color: white;
    padding: 8px 16px;
    font-weight: 700;
    outline: none;
    border-radius: 6px;
    text-transform: capitalize; 
    cursor: pointer;
}
.logout-btn{
    /* display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center; */
    max-width: 120px;
    background: #FF9900;
    border:1px solid #FF9900;
    color: white;
    padding: 8px 16px;
    font-weight: 700;
    outline: none;
    border-radius: 6px;
    text-transform: capitalize; 
    cursor: pointer;
    /* margin: 0px 30px 0px auto; */
}
.account-details-innercontainer{
    width: 96%;
    margin: auto;
    background: rgba(229, 229, 229, 0.5);
    border-radius: 5px;
}
.account-details-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.account_label{
    display:block;
    text-transform: capitalize;
}
.Accountform-input{
    color: #8492A6;
    font-size: 15px;
    font-weight: 600;
    /* width: 100%; */
    width: 450px;
  border: 1px solid #ffffff;
    border-radius: 6px;
    padding: 10px;
    outline: none;
    background-color: #ffffff;
}
.accountform-wrapper{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 15px 0px;
    flex-wrap: wrap;
}
/* .accountform-halfwrapper-1{
    width: 60%;
}
.accountform-halfwrapper-2{
    width: 40%;
} */

.billing-box{
    background: #ffffff;
    /* width: 60%; */
    border-radius: 5px;
    overflow: auto;
    padding: 10px;
}
.billing-box-lists{
    display: flex;
    justify-content: space-between;
    width: 90%;
}

.billing-box-title{
    width: 60%;
    text-transform: capitalize;
    color: #0A0A0A;
}
.billing-box-value{
    width: 40%;
    color: #737070;
}
.gold{
    color: #FF9900;
}
.billing-title,.account-title{

    text-transform: capitalize;
color: #0A0A0A;

}
/* .account-details-titlecontainer{
    display: flex;
    width: 100%;
    justify-content: space-between;
} */
.account-details-titlebuttons{
display: flex;
justify-content:flex-end;
align-items: center;
flex-wrap: wrap;
}
.save-btn{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    max-width: 80px;
    background: #FF9900;
    border:1px solid #FF9900;
    color: white;
    padding: 8px 16px;
    font-weight: 700;
    outline: none;
    border-radius: 6px;
    text-transform: capitalize; 
    cursor: pointer;
    margin: 0px auto 0px 0px;
}
.error-message{
    font-size: 10px;
    font-weight: 500;
    color: #ff0000;
}

/* subscription-table */

.subscriptionbill-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    flex-wrap: wrap;
    padding: 10px;
    margin: 10px;
}
.subscriptionbill-box .subscription-plantype h4{
  font-size: 16px;
  font-weight: 600;
}
.subscriptionbill-box .subscription-plantype p{
    font-size: 15px;
    text-transform: capitalize;

  }

/* responsive */

@media only screen and (max-width:1200px)  and (min-width:992px){
    .Accountform-input{
        width: 380px;
    }
    
}

@media only screen and (max-width:992px) and (min-width:768px) {
    .accountform-wrapper{
        display: block;
        margin: 15px 0px;
    }
    .Accountform-input{
        width: 100%;
    }
    .billing-box{
        width: 100%;
    }
    .subscriptionbill-box{
        width: 100%;
    }
}
@media only screen and (max-width:767px) and (min-width:520px) {
    .accountform-wrapper{
        display: block;
        margin: 15px 0px;
    }
    .Accountform-input{
        width: 100%;
    }
    .billing-box{
        width: 100%;
    }
    .subscriptionbill-box{
        width: 100%;
    }

}
@media only screen and (max-width:520px) and (min-width:320px) {
    .accountform-wrapper{
        display: block;
        margin: 15px 0px;
    }
    .Accountform-input{
        width: 100%;
    }
    .billing-box{
        width: 100%;
    }
    .subscriptionbill-box{
        width: 100%;
    }
    .billing-box-lists{
        display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    }
    .billing-box-title {
        width: 100%;
        text-transform: capitalize;
        color: #0A0A0A;
    }
    .gold{
        color: #FF9900 !important;
        width: 100%;
    }
    .billing-box-value {
        width: 100%;
        color: #737070;
    }
.subscription-plantype{
    margin-bottom: 10px;
}

}


/* react pop-up modal */


.mymodal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    width: 100%;
    min-width: 320px;
    max-width: 490px;
  }
  
  .myoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: rgba(0, 0, 0, 0.75); */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
  }
  
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
  }
  
  .ReactModal__Overlay--after-open {
    opacity: 1;
    z-index: 999;
  }
  
  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
  
  .Accountform-resetinput{
    color: #8492A6;
    font-size: 15px;
    font-weight: 600;
    /* width: 100%; */
    width: 450px;
  border: 1px solid rgba(229, 229, 229, 0.5);
    border-radius: 6px;
    padding: 10px;
    outline: none;
    background-color: rgba(229, 229, 229, 0.5);
  }
.account-details-resetbuttons{
    text-align:center;
    margin-top: 10px;
}
