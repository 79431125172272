.extension-wrapper-1{
    margin: 20px auto;
   
    border-radius: 5px;
    display: flex;
    width: 85%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background: #F8F9FC;

}
.extension-header{
    width: 95%;
    margin: 10px auto;
    padding: 46px;
    background: #ffffff;
    border-radius: 10px;
}
.extension-heading{
    color: #0a0a0a;
    margin: 0px 10px;
}
.extension-subheading{
    color: #a0a0a0;
    margin: 0px 10px;
    text-align: center;
   
}
.extension-image-wrapper{
    width: 45%;
}
.blogoneImage{
    /* min-width: 300px; */
    width:100%;
    max-width: 425px;
    margin: 10px;
    border-radius: 5px;
}
.extension-title{
margin:15px 20px;
}
.extension-paragraph{
    margin: 15px 20px;
    font-size: 15px;
   
    font-weight: 300;
}

.extention-wrapper-text{
    padding: 8px 15px;
    width: 55%;
}
.extension-btn{
    color: #ff9900;
    text-transform: capitalize;
    margin: 15px 20px;
    display: flex;
    justify-content: space-between;
    width: fit-content;
    align-items: center;
    text-decoration: none;
}
.extension-text{
    display: inline-block;
    margin-right: 10px;
}
.extension-icon{
    display: inline-block;
    padding-top: 5px;
}

/* responsive */
@media only screen and (max-width:992px) and (min-width:767px) {
    .extension-image-wrapper {
        width: 100%;
        margin-top: 15px;
        /* margin-right: auto; */
        text-align: center;
     }
     /* .blogoneImage {
        width: 350px;
        border-radius: 5px;
    } */
    .extention-wrapper-text {
        padding: 8px 15px;
        width: 100%;
    }
}


@media only screen and (max-width:767px) and (min-width:520px) {
    .extension-image-wrapper {
        width: 100%;
        margin-top: 15px;
        /* margin-right: auto; */
        text-align: center;
     }
     /* .blogoneImage {
        width: 350px;
        border-radius: 5px;
    } */
    .extention-wrapper-text {
        padding: 8px 15px;
        width: 100%;
    }
}

@media only screen and (max-width:520px) and (min-width:320px) {
    .extension-image-wrapper {
        /* width: 100%; */
        margin-top: 15px;
        margin-right: auto;
        text-align: center;
     }
     .blogoneImage {
        width: 250px;
        border-radius: 5px;
    }
    .extention-wrapper-text {
        padding: 8px 15px;
        width: 100%;
    }
}

