
.customdeal-form-modal{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    width: 100%;
    min-width: 320px;
    max-width: 490px;
  }
.customdeal-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
.customdeal-text-input{
    border: 1px solid #92929e;
    border-radius: 5px;
    box-shadow: none;
    padding: 9px 10px;
    outline: none;
    max-width: 250px;
}
.customdeal-select{
    border: 1px solid #92929e;
    border-radius: 5px;
    box-shadow: none;
    padding: 9px 10px;
    outline: none;
    max-width: 250px;
    cursor: pointer;
}
.customdeal-select>option{
    cursor: pointer;
}
.customdealbtn-conatiner{
    text-align: right;
    padding: 10px 0px;
}
.customdeal-flex-radio{
    display: flex;
    justify-content: space-between;
    width: 35%;
}
.customdeal-error-msg{
    display: block;
    font-size: 13px;
    color: red;
    margin: 5px 0px;
}