.thankyou-page-container {
  background-color: #edf5ff;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}
.giftbox-image {
  display: block;
  height: 80px;
  width: 80px;
  margin: 0px auto;
  padding: 10px;
}
.smile-icon svg {
  color: #f90;
  font-size: 29px;
  margin: 0px 2px;
}
.thank-text-container {
  text-align: center;
}
.thank-text-wrapper {
  font-size: 45px;
  color: #f90;
  font-weight: 300;
}
.thank-text-wrapper-2 {
  font-size: 22px;
}
@media screen and (max-width: 520px) {
  .thank-text-wrapper {
    font-size: 30px;
  }
  .smile-icon svg {
    font-size: 20px;
    margin: 0px 2px;
  }
  .thank-text-wrapper-2 {
    font-size: 15px;
  }
}
