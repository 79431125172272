* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  /* overflow: hidden; */
}

body {
  background-color: #e5e5e5;
  font-family: "Poppins", sans-serif;
}

:root {
  --black: #0a0a0a;
  --orange: #ff9900;
  --blue: #1890ff;
  --light-black: #3b3b3b;
  --light-grey: #a0a0a0;
  --green: #0fb158;
  --green-text: #009744;
}

a {
  cursor: pointer;
}
a:hover {
  color: #ff9900;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
button:disabled {
  cursor: not-allowed;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.row {
  display: flex;
  justify-content: center;
}

/* padding values */
/* padding top */
.pt-1 {
  padding-top: 5px;
}

.pt-2 {
  padding-top: 10px;
}

.pt-3 {
  padding-top: 15px;
}

.pt-4 {
  padding-top: 20px;
}

.pt-5 {
  padding-top: 25px;
}

.pt-6 {
  padding-top: 30px;
}

.pt-7 {
  padding-top: 35px;
}

.pt-8 {
  padding-top: 40px;
}

.pt-9 {
  padding-top: 45px;
}

.pt-10 {
  padding-top: 50px;
}

/* padding-right */
.pr-1 {
  padding-right: 5px;
}

.pr-2 {
  padding-right: 10px;
}

.pr-3 {
  padding-right: 15px;
}

.pr-4 {
  padding-right: 20px;
}

.pr-5 {
  padding-right: 25px;
}

.pr-6 {
  padding-right: 30px;
}

.pr-7 {
  padding-right: 35px;
}

.pr-8 {
  padding-right: 40px;
}

.pr-9 {
  padding-right: 45px;
}

.pr-10 {
  padding-right: 50px;
}

/* padding-left */
.pl-1 {
  padding-left: 5px;
}

.pl-2 {
  padding-left: 10px;
}

.pl-3 {
  padding-left: 15px;
}

.pl-4 {
  padding-left: 20px;
}

.pl-5 {
  padding-left: 25px;
}

.pl-6 {
  padding-left: 30px;
}

.pl-7 {
  padding-left: 35px;
}

.pl-8 {
  padding-left: 40px;
}

.pl-9 {
  padding-left: 45px;
}

.pl-10 {
  padding-left: 50px;
}

/* padding-bottom */
.pb-1 {
  padding-bottom: 5px;
}

.pb-2 {
  padding-bottom: 10px;
}

.pb-3 {
  padding-bottom: 15px;
}

.pb-4 {
  padding-bottom: 20px;
}

.pb-5 {
  padding-bottom: 25px;
}

.pb-6 {
  padding-bottom: 30px;
}

.pb-7 {
  padding-bottom: 35px;
}

.pb-8 {
  padding-bottom: 40px;
}

.pb-9 {
  padding-bottom: 45px;
}

.pb-10 {
  padding-bottom: 50px;
}

/* margin values */
/* margin-top */
.mt-1 {
  margin-top: 5px;
}

.mt-2 {
  margin-top: 10px;
}

.mt-3 {
  margin-top: 15px;
}

.mt-4 {
  margin-top: 20px;
}

.mt-5 {
  margin-top: 25px;
}

.mt-6 {
  margin-top: 30px;
}

.mt-7 {
  margin-top: 35px;
}

.mt-8 {
  margin-top: 40px;
}

.mt-9 {
  margin-top: 45px;
}

.mt-10 {
  margin-top: 50px;
}

/* margin-right */
.mr-1 {
  margin-right: 5px;
}

.mr-2 {
  margin-right: 10px;
}

.mr-3 {
  margin-right: 15px;
}

.mr-4 {
  margin-right: 20px;
}

.mr-5 {
  margin-right: 25px;
}

.mr-6 {
  margin-right: 30px;
}

.mr-7 {
  margin-right: 35px;
}

.mr-8 {
  margin-right: 40px;
}

.mr-9 {
  margin-right: 45px;
}

.mr-10 {
  margin-right: 50px;
}

/* margin-left */
.ml-1 {
  margin-left: 5px;
}

.ml-2 {
  margin-left: 10px;
}

.ml-3 {
  margin-left: 15px;
}

.ml-4 {
  margin-left: 20px;
}

.ml-5 {
  margin-left: 25px;
}

.ml-6 {
  margin-left: 30px;
}

.ml-7 {
  margin-left: 35px;
}

.ml-8 {
  margin-left: 40px;
}

.ml-9 {
  margin-left: 45px;
}

.ml-10 {
  margin-left: 50px;
}

/* margin-bottom */
.mb-1 {
  margin-bottom: 5px;
}

.mb-2 {
  margin-bottom: 10px;
}

.mb-3 {
  margin-bottom: 15px;
}

.mb-4 {
  margin-bottom: 20px;
}

.mb-5 {
  margin-bottom: 25px;
}

.mb-6 {
  margin-bottom: 30px;
}

.mb-7 {
  margin-bottom: 35px;
}

.mb-8 {
  margin-bottom: 40px;
}

.mb-9 {
  margin-bottom: 45px;
}

.mb-10 {
  margin-bottom: 50px;
}

.container {
  display: flex;
}

.container-width {
  width: 95%;
  margin-left: 100px;
}

/* sidebar */
.sideBar {
  width: 100px;
  height: 100vh;
  background: white;
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 1;
}

.text-center {
  text-align: center;
}
.sidebar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.sidebar-icon {
  display: block;
}

.sidebar-icon svg {
  font-size: 20px;
}

.sidebar-logout-icon {
  display: block;
}

.sidebar-logout-icon svg {
  /* color: #A0A0A0; */
  font-size: 20px;
}

.logoImage {
  width: 48px;
  margin: 25px auto 0 auto;
  height: 48px;
  border-radius: 50%;
}

.sidebar-list {
  padding: 0;
  /* margin: 100px 0px; */
}

.sidebar-list li {
  list-style: none;
  padding: 8px 0;
  text-transform: capitalize;
  font-size: 13px;
}
.sidebar-list li:focus-visible {
  outline: none;
}
.sidebar-list li a {
  color: #a0a0a0;
  text-decoration: none;
}
.sidebar-list li a:focus-visible {
  outline: none;
}
.sidebar-logout a {
  color: #a0a0a0;
  text-decoration: none;
  font-size: 13px;
}

.active {
  /* background-color: red; */
  color: #0e0e0e !important;
  position: relative;
}
.active::after {
  position: absolute;
  content: " ";
  background: black;
  width: 5px;
  height: 100%;
  bottom: 0px;
  left: -50px;
  top: 0px;
  border-radius: 5px;
}

/* top bar */

.topbar {
  width: 100%;
}

.topbar-container {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: auto;
  flex-wrap: wrap;
}

.top-bg-white {
  background-color: white;
}

.topbar-list-items-1 {
  display: flex;
  justify-content: space-between;
  width: 98%;
  flex-wrap: wrap;
  margin-top: 10px;
}

.topbar-list-1 {
  /* width: 59%; */
  width: 74%;

  /* padding: 0 25px; */
  padding: 25px;

  border-radius: 10px;
}

.topbar-list-2 {
  width: 17%;
  padding: 0 25px;
  border-radius: 10px;
}

.topbar-list-3 {
  /* width: 20%; */
  width: 24%;
  padding: 0 25px;
  border-radius: 10px;
}

.topbar-img-list-3 {
  /* width: 20%; */
  width: 24%;

  border-radius: 10px;
  /* display: flex;
    align-items: center; */
}

.list-item-title {
  font-size: 15px;
  margin: 0;
  font-weight: 700;
  text-transform: uppercase;
  color: #0a0a0a;
  text-align: center;
}
.orange {
  color: #ff9900;
}
.list-item-value {
  font-size: 20px;
  margin: 0px 0px 10px 0px;
  font-weight: 800;
  color: #0a0a0a;
  text-align: center;
}

.topbar-list-items-1-title,
.topbar-list-items-2-title,
.topbar-list-items-3-title {
  color: #a0a0aa;
  font-weight: 600;
  font-size: 15px;
}

.list-item-value-span {
  color: #a0a0aa;
  font-weight: 600;
  font-size: 15px;
}

/* product tab */
.productbar {
  position: sticky;
  top: 0px;
  z-index: 999;
  width: 95%;
  margin: 10px auto;
}

.productbar-container {
  /* width: 95%; */
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  background: white;
  border-radius: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.producttab-left {
  /* width: 21%; */
  width: 38%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.producttab-center {
  /* width: 22%; */

  /* display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1 1 auto; */
}
.producttab-search-center {
  /* width: 23%; */
  /* display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1 1 auto; */
}
.producttab-right {
  /* width: 22%; */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1 1 auto;
}
.producttab-end {
  /* width: 22%; */
  /* display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1 1 auto; */
}

.producttab-left-deals {
  display: flex;
  width: 100%;
  justify-content: space-around;
  cursor: pointer;
}
.input-search-box {
  padding: 8px 20px;
  width: 200px;
  border: 1px solid #a0a0a0;
  outline: none;
  border-radius: 5px;
}
.product-heading {
  /* width: 100%; */
  width: 93%;

  display: flex;
  /* width: 70%; */
  justify-content: space-between;
  align-items: center;
}

/* .producttab-center-deals {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
} */

/* .producttab-right-deals {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
} */

/* product status bar */

.productcard-status-container {
  background-color: #fff;
  /* padding: 10px; */
  width: 95%;
  margin: 10px auto;
  border-radius: 6px;
}
.productcard-status-wrapper {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
}

.alldeal-title-active {
  font-size: 16px;
  font-weight: 800;
  position: relative;
  margin-right: 15px;
  cursor: pointer;
  padding: 10px;
  color: #000;
}
.alldeal-title-active::after {
  background: #f90;
  bottom: 2px;
  content: " ";
  height: 4px;
  left: 0;
  position: absolute;
  width: 100%;
}

.alldeal-titles {
  font-size: 16px;
  font-weight: 100;
  position: relative;
  margin-right: 15px;
  cursor: pointer;
  padding: 10px;
  color: #222222;
}

.reqdeal-title-active {
  font-size: 16px;
  font-weight: 800;
  width: 100%;
  position: relative;
  padding: 10px;
  cursor: pointer;
  color: #000;
}
.reqdeal-title-active::after {
  background: #f90;
  bottom: 2px;
  width: 100%;
  content: " ";
  height: 4px;
  left: 0;
  position: absolute;
  width: 100%;
}

.search-icon-btn {
  position: absolute;
  background: transparent;
  right: 3px;
  padding: 3px;
  border: none;
  outline: none;
  top: 5px;
}
.search-icon-btn {
  font-size: 15px;
}
.search-form {
  position: relative;
  width: fit-content;
}
.tab-title {
  font-size: 16px;
}
.todaydeal-title {
  text-transform: capitalize;
  font-weight: 800;
  position: relative;
}

.todaydeal-title::after {
  position: absolute;
  content: " ";
  background: black;
  width: 100%;
  height: 4px;
  bottom: -12px;
  left: 0;
}

.alldeal-title {
  text-transform: capitalize;
  font-weight: 800;
  color: #0a0a0a;
  position: relative;
}
.alldeal-title::after {
  position: absolute;
  content: " ";
  background: black;
  width: 100%;
  height: 4px;
  bottom: -12px;
  left: 0;
  color: #0a0a0a !important;
}

.searchbar-span {
  display: flex;
  /* width: 40%; */
  position: relative;
}

.product-tab-searchbar {
  outline: none;
  border: 1px solid #999999;
  padding: 8px 25px;
  border-radius: 5px;
  max-width: 250px;
  width: 100%;
}

.search-btn {
  outline: none;
  border: none;
  color: #0a0a0a;
  padding: 8px 10px;
  border-radius: 5px;
  position: absolute;
  left: -5px;
  background: transparent;
  cursor: pointer;
}

.search-btn svg {
  font-size: 18px;
}

.category-dropdown {
  width: 180px;
  border: 1px solid #a0a0a0;
  outline: none;
  padding: 8px 10px;
  border-radius: 5px;
  background-color: #ffffff;
  font-weight: 500;
}
.category-dropdown-sort {
  width: 180px;
  border: 1px solid #a0a0a0;
  outline: none;
  padding: 8px 10px;
  border-radius: 5px;
  background-color: #ffffff;
  font-weight: 500;
}
.category-dropdown-plan {
  width: 120px;
  border: 1px solid #a0a0a0;
  outline: none;
  padding: 8px 10px;
  border-radius: 5px;
  background-color: #ffffff;
  font-weight: 500;
}
.category-dropdown option {
  background: #f8f9fc;
  /* padding: 10px ; */
  font-size: 15px;
  color: #0a0a0a;
}

/* .category-dropdown-span {
  width: 30%;
} */
.producttab-right-filter-div,
.producttab-center-filter-div {
  /* display: flex; */
  color: #0a0a0a;
  /* border: 1px solid #a0a0a0; */
  /* width: 23%; */
  border-radius: 10px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.producttab-center-filter-container {
  display: flex;
  align-items: center;
  padding: 3px 8px;
  justify-content: space-around;
}

.filter-div {
  display: flex;
  color: #0a0a0a;
  border: 1px solid #a0a0a0;
  width: 15%;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.filter-icon svg {
  font-size: 15px;
}

.filter-icon {
  /* margin-left: 10px;
    line-height: 30px;
    width: 20%; */
  margin-top: 3px;
  margin-right: 5px;
}
/* .filter-span-text{
    width: 80%;
} */
.dropdown-filter-content {
  box-shadow: rgba(0, 0, 0, 0.16) 0 0 10px;
  position: absolute;
  top: 35px;
  background: #f8f9fc;
  width: max-content;
  padding: 10px 25px;
  border-radius: 5px;
  right: 0px;
}
.dropdown-content-lists {
  list-style: none;
  cursor: pointer;
}
.dropdown-content-lists li {
  padding: 10px;
}
/* .filter-div:hover .dropdown-filter-content{
    display: block;
} */

.dropdown-content-lists li:hover {
  background-color: #f1f1f1;
}
.width-95 {
  width: 95%;
  margin: auto;
}

/* productcard */
.productcard {
  width: 95%;
  margin: 10px auto;
  background: white;
  border-radius: 10px;
}
.productcard-wrapper {
  margin-bottom: 14px;
}
.buydealPrice-icon {
  margin-top: 10px;
  text-align: center;
  font-size: 18px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.bulkdealsPriceicon {
  margin: 20px -34px 0px 3px !important;
}
.buydealPrice-icon > svg {
  margin-right: 7px;
  font-size: 28px;
  color: #ffd700;
}
.product-details {
  /* display: flex;
  justify-content: space-between;
  background: #f8f9fc;
  border-radius: 10px;
  align-items: center;
  width: 100%; */
  /* flex-wrap: wrap; */
  width: 80%;
}

.product-image-title {
  display: flex;
  /* justify-content: space-between; */
  /* width: 85%; */
  align-items: center;
  /* flex-wrap: wrap; */
}
.addtocart {
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  /* width: 15%; */
}

.product-image {
  width: 100px;
  margin-right: 20px;
}
/* .product-heading{
    width: 90%;
} */
.productimage {
  /* width: 100%; */
  max-width: 75px;
  max-height: 75px;
  /* height: auto; */
  filter: drop-shadow(4px 4px 5px rgba(215, 215, 215, 0.5));
  /* height: auto; */
  /* height: 90px; */
}

.product-title {
  color: #0a0a0a;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: capitalize;
}

.category-title {
  color: #a0a0a0;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: capitalize;
}

.product-buttons {
  display: flex;
  justify-content: space-between;
  width: 18%;
  align-items: center;
  cursor: default;
}

.new-btn {
  display: flex;
  width: 75px;
  justify-content: space-evenly;
  align-items: center;
  background: #0fb158;
  color: white;
  outline: none;
  border: 1px solid #0fb158;
  padding: 4px 15px 4px 10px;
  border-radius: 5px;
  cursor: default;
  font-size: 13px;
  line-height: 1px;
  margin-right: 20px;
}

.new-btn svg {
  font-size: 13px;
}

.new-btn-span {
  margin-right: 10px;
}

.update-btn {
  background: #3b3b3b;
  color: white;
  outline: none;
  border: 1px solid #3b3b3b;
  padding: 4px 15px 4px 10px;
  border-radius: 5px;
  cursor: default;
  display: flex;
  width: 85px;
  text-transform: capitalize;
  justify-content: space-evenly;
  align-items: center;
  font-size: 13px;
  line-height: 1px;
  margin-right: 20px;
}

.update-btn svg {
  font-size: 13px;
}

.update-btn-span {
  margin-right: 10px;
}

.plan-btn {
  background: #ff9900;
  color: white;
  border: 1px solid #ff9900;
  outline: none;
  /* padding: 4px 15px 4px 10px; */
  padding: 3px 10px;

  border-radius: 5px;
  cursor: default;
  max-width: 85px;
  text-transform: capitalize;
  font-size: 13px;
  /* line-height: 1px; */
}

/* .plan-btn svg {
  font-size: 13px;
}

.plan-btn-span {
  margin-right: 10px;
} */

.cart-btn,
.heart-btn {
  background: white;
  color: black;
  border-radius: 5px;
  padding: 5px 7px;
  border: none;
  margin-right: 10px;
  cursor: pointer;
  transition: 300ms;
  line-height: 10px;
}
.heart-btn:active {
  background-color: #ffffff;
  color: #fff;
}
.cart-btn svg,
.heart-btn svg,
.like-btn svg,
.dislike-btn svg {
  font-size: 22px;
}
/* .cartbtn_highlight{
  background-color: #ff9900;
  color: #0e0e0e;

  
}
.heart-btn_hightlight{
  background-color: #ff9900;
  color: #0e0e0e;

} */

/* .liked{
  background: #009744 !important;
  color: #ffffff !important;
}

.disliked{
  background: red !important;
  color: #ffffff !important;
} */

/* .cart-btn:hover,
.heart-btn:hover {
  background: #ff9900;
  color: white;
  border-radius: 5px;
  padding: 5px 7px;
  border: none;
  margin-right: 10px;
  cursor: pointer;
} */
/* .cart-btn:active,
.heart-btn:active{

} */

.like-btn {
  background: #dffded;
  color: #01ac4e;
  outline: none;
  border: 1px solid #dffded;
  padding: 5px 7px;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
  line-height: 10px;
}

.dislike-btn {
  background-color: #fde6e6;
  color: #ff5e5e;
  outline: none;
  border: 1px solid #fde6e6;
  padding: 5px 7px;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
  line-height: 10px;
}
.loadmore-btn {
  max-width: 120px;
  /* background:  white;
    border: 1px solid #1890ff;
    color: #1890ff; */
  background: #ff9900;
  border: 1px solid #ff9900;
  color: white;
  padding: 8px 16px;
  font-weight: 700;
  outline: none;
  border-radius: 6px;
  text-transform: capitalize;
  cursor: pointer;
  transition: 300ms;
  text-decoration: none;
  font-size: 13px;
  margin: 10px 0px;
}

/* product info */

.product-container {
  background: white;
  border-radius: 10px;
}

.product-info {
  /* display: flex; */
  width: 100%;
  margin: 5px auto 0px auto;
  flex-wrap: wrap;
}

.product-table-info {
  /* width: 73%; */
  width: 100%;
  flex: 1 1 auto;
  background: #f8f9fc;
  border-radius: 5px;
}

.calculator {
  /* background: #01AC4E; */
  /* width: 25%; */
  flex: 0 1 auto;
}

.product-table {
  /* display: flex;
  justify-content: space-between;
  width: 85%;
  flex-wrap: wrap; */
  display: flex;
  justify-content: space-between;
  width: 96%;
  flex-wrap: wrap;
  padding: 1%;
}

.product-table-title {
  /* color: #3b3b3b; */
  color: #0e0e0e;
  font-size: 15px;
}

.product-table-value {
  /* color: #8492a6; */
  color: #333333;
  /* margin-bottom: 15px; */
  font-size: 14px;
  font-weight: 500;
}
.product-table-custom {
  margin-top: 28px;
}
/* .product-header-table>thead>tr>th{
  border-width: 2px;
    border-style: solid;
    padding: 3px;
} */
.product-offer-info {
  color: #999999;
}

.product-offer-info-title {
  color: #3b3b3b;
  font-size: 15px;
}

.product-offer-info-value {
  color: #8492a6;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 500;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.rulerline {
  background-color: #d3d3d3;
  height: 1px;
  border: none;
  outline: none;
  width: 98%;
}

.product-offer-table {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.product-offer-table-list-1 {
  width: 50%;
}
.product-offer-table-list-2 {
  width: 50%;
}

.graph-btn {
  /* background: white; */
  background: #01ac4e;
  border: 1px solid #999999;
  color: #555454;
  /* padding: 8px 16px; */
  font-weight: 700;
  outline: none;
  border-radius: 6px;
  text-transform: capitalize;
  cursor: pointer;
  /* box-shadow: 3px 3px 5px 0px rgb(0 0 0 / 75%); */
  color: #fff;
  /* max-width: 120px; */
  width: fit-content;
  padding: 6px 10px;
}
.graph-btn:hover {
  background: #ffffff;
  border: 1px solid #01ac4e;
  color: #01ac4e;
}
/* calculator */
.loading-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}
.loading-title {
  padding: 15px 0;
}
.loading-para {
  padding: 15px 0;
}

.calculator-container {
  background: #0a0a0a;
  color: white;
  border-radius: 5px;
  width: 75%;
  margin: auto;
  height: 300px;
  max-width: 400px;
  min-width: 260px;
}

.calcultor-list-1 {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto 15px auto;
  align-items: center;
  text-transform: capitalize;
}

.calculator-title {
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-weight: 800;
}

.calculator-input-value {
  color: #ff9900;
  font-size: 15px;
  font-weight: 600;
  width: 40%;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  outline: none;
  background-color: #ffffff;
}

.calculator-label {
  font-weight: 500;
}

.calcultor-list-2 {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto 10px auto;
  align-items: center;
  text-transform: capitalize;
}

.calculator-input {
  width: 40%;
}

.calculated-value {
  color: #ff9900;
  font-weight: 600;
}

.calculate-btn {
  text-align: center;
  padding: 7px 20px;
  color: white;
  background: #ff9900;
  border-radius: 10px;
  border: 1px solid #ff9900;
  outline: none;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
}

.message {
  background: #dffded;
  color: #009744;
  border-radius: 10px;
  width: 80%;
  margin: 10px auto 3px auto;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border: 1px solid #009744;
  line-height: 10px;
  max-width: 400px;
}

.message span svg {
  font-size: 20px;
  margin-right: 10px;
}

.amazon-btn {
  /* display: flex; */
  width: 100%;
  /* justify-content: space-evenly; */
  /* align-items: center; */
  max-width: 120px;
  background: #ff9900;
  border: 1px solid #ff9900;
  color: white;
  /* padding: 8px 16px; */
  padding: 6px 10px;
  margin-right: -12px;
  font-weight: 700;
  outline: none;
  border-radius: 6px;
  text-transform: capitalize;
  cursor: pointer;
  transition: 300ms;
  text-decoration: none;
  font-size: 13px;
  /* box-shadow: 3px 3px 5px 0px rgb(0 0 0 / 75%); */
}

.request-btn {
  display: flex;
  /* width: 100%; */
  justify-content: space-evenly;
  align-items: center;
  /* max-width: 120px; */
  background: none;
  border: none;
  color: white;
  /* padding: 8px 16px; */
  /* padding: 6px 10px; */
  /* margin-right: -12px; */
  font-weight: 700;
  outline: none;
  border-radius: 6px;
  text-transform: capitalize;
  cursor: pointer;
  transition: 300ms;
  text-decoration: none;
  font-size: 13px;
  /* box-shadow: 3px 3px 5px 0px rgb(0 0 0 / 75%); */
}
.buys-dealbtn {
  text-align: center !important;
}
.amazon-btn:hover {
  background: #ffffff;
  border: 1px solid #ff9900;
  color: var(--orange);
}

.request-btn:hover {
  background: #ffffff;
  border: none;
  color: var(--orange);
}

.request-btn svg {
  font-size: 20px;
}
.amazon-btn svg {
  font-size: 20px;
}

.store-btn {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  max-width: 120px;
  background: #1890ff;
  border: 1px solid #1890ff;
  color: white;
  padding: 8px 16px;
  font-weight: 700;
  outline: none;
  border-radius: 6px;
  text-transform: capitalize;
  cursor: pointer;
  transition: 300ms;
  text-decoration: none;
  font-size: 13px;
  box-shadow: 3px 3px 5px 0px rgb(0 0 0 / 75%);
}
.store-btn:hover {
  background: #ffffff;
  border: 1px solid #1890ff;
  color: #1890ff;
}
.store-btn svg {
  font-size: 20px;
}

.calculator-buttons {
  display: flex;
  justify-content: space-around;
  width: 320px;
  flex: 0 1 auto;
}

.keppagraph-buttons {
  display: flex;
  width: 96%;
  margin: 5px auto 0px auto;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 10px;
}

.black-color {
  background-color: #0a0a0a;
}

/* spinner */

#loading {
  display: inline-block;
  width: 70px;
  height: 70px;
  border: 5px solid #ffffff;
  border-radius: 50%;
  border-top-color: #ff9900;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

/* wishlist */
.wishlist-product-details {
  display: flex;
  justify-content: space-between;
  background: #f8f9fc;
  border-radius: 10px;
  align-items: center;
  width: 100%;
  /* flex-wrap: wrap; */
}
.productcard-wishlist {
  width: 100%;
}
.min-container-wishlist {
  width: 100%;
  margin: 0 auto 15px auto;
}

.wishlist-product-dealmp-containter {
  /* margin:0 ; */
  margin: 0 0px 10px !important;
}

.wishlist-product-table-info {
  width: 95%;
  background: #f8f9fc;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}
.wishlist-header {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 10px;
  align-items: center;
  width: 95%;
  margin: 10px auto;
  padding: 40px;
}
.wishlist-product-table-value {
  color: #8492a6;
  margin-bottom: 25px;
  font-size: 14px;
  font-weight: 500;
}
.wishlist-product-table-title {
  color: #3b3b3b;
  font-size: 15px;
  margin-bottom: 5px;
}
.wishlist-product-offer-info-title {
  color: #3b3b3b;
  font-size: 15px;
  margin-bottom: 5px;
}
.wishlist-product-offer-info-value {
  color: #8492a6;
  margin-bottom: 27px;
  font-size: 14px;
  font-weight: 500;
}
.w-70 {
  width: 80%;
}
.wishlist-searchfield {
  /* width: 40%; */
  position: relative;
  margin-right: 30px;
}
.purchased-filter-div {
  width: fit-content;
  display: flex;
  color: #0a0a0a;
  /* border: 1px solid #a0a0a0; */

  border-radius: 10px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.wishlist-searchbar {
  outline: none;
  border: 1px solid #999999;
  padding: 8px 10px;
  border-radius: 5px;
  max-width: 270px;
  width: 100%;
}
.wishlist-search-btn {
  outline: none;
  border: none;
  color: #0a0a0a;
  padding: 8px 10px;
  border-radius: 5px;
  position: absolute;
  right: 105px;
  background: transparent;
  cursor: pointer;
  /* right: 0px */
}

.wishlist-product-offer-table {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.wishlist-product-offer-table-list-1 {
  width: 32%;
}
.wishlist-product-offer-table-list-2 {
  width: 32%;
}
.wishlist-product-offer-table-list-3 {
  width: 32%;
}

.wishlist-store-buttons {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: auto;
  flex-wrap: wrap;
}
.wishlist-search-btn svg {
  font-size: 18px;
}

/* header component */
.responsiveheader-container {
  display: none;
}

/* hamburger menu */

.bm-burger-button {
  position: relative;
  width: 31px;
  height: 23px;
  right: 15px;
  top: 1px;
}

.bm-burger-bars {
  background: black;
  height: 15% !important;
}

.bm-burger-bars-hover {
  background: #ff9900;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu-wrap {
  position: fixed;
  left: 0px !important;
  z-index: 1100;
  width: 300px;
  height: 100%;
  transition: all 0.5s ease 0s;
  top: 0px !important;
}

.bm-menu {
  background: #ffffff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  overflow: inherit !important;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #5b769e;
  padding: 0.8em;
}

.bm-item {
  display: inline-block;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  width: 0% !important;
}

.bm-item {
  display: inline-block;
  color: #d1d1d1;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
}

.bm-item:hover {
  color: #14638b;
}

.bm-item-list > a {
  display: block;
  font-weight: 400;
  padding: 10px 0px;
  text-decoration: none;
  color: #fff !important;
  font-size: 16px;
}
.bm-item-list > a:active {
  color: #5b769e !important;
}

#react-burger-menu-btn {
  font-size: 0px !important;
}

#react-burger-cross-btn {
  font-size: 0px !important;
}
#react-burger-menu-btn:hover {
  background-color: inherit !important;
}
.hamburger-nav-links {
  padding-bottom: 15px;
}
.empty-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  font-size: 22px;
  font-weight: 900;
  margin: 10px 0px;
}
.canvasjs-chart-credit {
  display: none;
}
.canvasjs-chart-toolbar button {
  display: none !important;
}

/* error page */
.error-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
}
.error-name {
  font-size: 10rem;
  font-weight: 800;
}
.error-name span {
  font-size: 2rem;
  font-weight: 800;
}
.error-para {
  font-size: 1.5rem;
}
.error-para a {
  color: #f90;
  margin-left: 10px;
  text-decoration: none;
}
.error-heading {
  font-size: 2rem;
  text-align: center;
}

/* responsive */

@media only screen and (max-width: 992px) and (min-width: 768px) {
  .sideBar {
    display: none;
  }
  .container-width {
    width: 100%;
    margin-left: 0px;
  }
  .responsiveheader-container {
    display: block;
    background: #fff;
    position: sticky;
    z-index: 10;
    top: 0px;
    padding: 12px;
  }
  .responsiveheader-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  .logoresponsiveImage {
    width: 48px;
    /* margin: 40px auto 0 auto; */
    height: 48px;
    border-radius: 50%;
  }
  .mobile-menuitem {
    list-style: none;
    padding: 10px 0px;
  }
  .mobile-menuitem a {
    text-decoration: none;
    color: #a0a0a0;
    display: flex;
    align-items: center;
  }
  .mobile-menuitem > a > svg {
    margin-right: 5px;
    margin-bottom: 3px;
  }

  .product-table-info {
    width: 100%;
  }
  .calculator {
    display: none;
  }
  /* .graph-btn {
    display: none;
  } */
  .calculator-buttons {
    display: flex;
    justify-content: space-between;
    width: 50%;
  }
  .topbar-list-1 {
    width: 46%;
    padding: 0 15px;
    border-radius: 10px;
  }
  .topbar-list-2 {
    width: 25%;
    padding: 0 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .topbar-list-3 {
    width: 25%;
    padding: 0 15px;
    border-radius: 10px;
  }
  .tab-title {
    font-size: 16px;
  }

  /* .producttab-left {
    width: 33%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.producttab-search-center {
  width: 33%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.producttab-center {
  width: 33%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.producttab-right {
  width: 49%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.producttab-end {
  width: 50%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;} */

  .topbar-list-items-1-div {
    width: 50%;
  }
  .category-dropdown {
    width: 200px;
    border: 1px solid #a0a0a0;
    outline: none;
    padding: 8px 10px;
    border-radius: 5px;
    background-color: #ffffff;
    font-weight: 500;
  }
  .productbar {
    position: inherit;
    /* top: 0px; */
  }
}

@media only screen and (max-width: 767px) and (min-width: 520px) {
  .sideBar {
    display: none;
  }
  .container-width {
    width: 100%;
    margin-left: 0px;
  }
  .responsiveheader-container {
    display: block;
    background: #fff;
    position: sticky;
    z-index: 10;
    top: 0px;
    padding: 12px;
  }
  .responsiveheader-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  .logoresponsiveImage {
    width: 48px;
    /* margin: 40px auto 0 auto; */
    height: 48px;
    border-radius: 50%;
  }
  .mobile-menuitem {
    list-style: none;
    padding: 10px 0px;
  }
  .mobile-menuitem a {
    text-decoration: none;
    color: #a0a0a0;
    display: flex;
    align-items: center;
  }
  .product-table-info {
    width: 100%;
  }

  .empty-content {
    flex-direction: column;
  }

  .product-details {
    display: flex;
    justify-content: space-between;
    background: #f8f9fc;
    border-radius: 10px;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
  }

  .product-image-title {
    display: flex;
    /* justify-content: space-between; */
    /* width: 85%; */
    align-items: center;
    /* flex-wrap: wrap; */
  }
  .addtocart {
    display: flex;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    /* width: 15%; */
  }
  .mobile-menuitem > a > svg {
    margin-right: 5px;
    margin-bottom: 3px;
  }

  .calculator {
    display: none;
  }
  /* .graph-btn {
    display: none;
  } */
  .calculator-buttons {
    display: flex;
    justify-content: space-between;
    width: 60%;
  }
  .tab-title {
    font-size: 15px;
  }

  /* .producttab-left {
    width: 45%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .producttab-search-center {
    width: 45%;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-right: 15px;
}
  .producttab-center {
    width: 33%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: 15px;
  }
  .producttab-right {
    width: 33%;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-right: 15px;
  }
  .producttab-end {
    width: 33%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;} */

  .topbar-list-1 {
    width: 100%;
    padding: 0 15px;
    border-radius: 10px;
  }
  .topbar-list-2 {
    width: 49%;
    margin-top: 10px;
    padding: 0 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* .topbar-list-3 {
    width: 49%;
    padding: 0 15px;
    margin-top: 10px;
    border-radius: 10px;
  } */

  .topbar-img-list-3 {
    /* 
    width: 49%; */
    width: 100%;
    padding: 0 15px;
    margin-top: 10px;
  }

  .wishlist-product-offer-table-list-1 {
    width: 50%;
  }
  .wishlist-product-offer-table-list-2 {
    width: 50%;
  }
  .wishlist-product-offer-table-list-3 {
    width: 100%;
  }

  .wishlist-store-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: auto;
    padding-right: 10px;
  }

  .productbar {
    position: inherit;
    /* top: 0px; */
  }
}

@media only screen and (max-width: 520px) and (min-width: 320px) {
  /* @media only screen and (max-width: 520px) { */

  .sideBar {
    display: none;
  }
  .container-width {
    width: 100%;
    margin-left: 0px;
  }
  .responsiveheader-container {
    display: block;
    background: #fff;
    position: sticky;
    z-index: 10;
    top: 0px;
    padding: 12px;
  }
  .responsiveheader-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  .logoresponsiveImage {
    width: 48px;
    /* margin: 40px auto 0 auto; */
    height: 48px;
    border-radius: 50%;
  }
  .mobile-menuitem {
    list-style: none;
    padding: 10px 0px;
  }
  .mobile-menuitem a {
    text-decoration: none;
    color: #a0a0a0;
    font-size: 13px;
    display: flex;
    align-items: center;
  }
  .mobile-menuitem > a > svg {
    margin-right: 5px;
    margin-bottom: 3px;
  }
  .product-table-info {
    width: 100%;
  }
  .empty-content {
    flex-direction: column;
    text-align: center;
  }
  .calculator {
    display: none;
  }
  /* .graph-btn {
    display: none;
  } */
  .calculator-buttons {
    display: flex;
    justify-content: space-between;
    width: 93%;
  }
  .producttab-left {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .producttab-left-deals {
    display: flex;
    width: 100%;
    justify-content: space-around;
    cursor: pointer;
  }

  .producttab-center {
    /* width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; */
  }
  .producttab-right {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .producttab-search-center {
    /* width: 100%; */
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* margin-top: 10px; */
  }
  .producttab-end {
    /* width: 22%; */
    /* display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1 1 auto; */
  }
  .category-dropdown {
    width: 140px;
    /* width: 180px; */
    border: 1px solid #a0a0a0;
    outline: none;
    padding: 8px 10px;
    border-radius: 5px;
    background-color: #ffffff;
    font-weight: 500;
  }
  .category-dropdown-sort {
    width: 140px;
  }
  .topbar-list-1 {
    width: 100%;
    padding: 0 15px;
    border-radius: 10px;
  }
  .topbar-list-2 {
    width: 49%;
    padding: 0 15px;
    margin-top: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tab-title {
    font-size: 15px;
  }
  /* .topbar-list-3 {
    width: 49%;
    padding: 0 15px;
    border-radius: 10px;
    margin-top: 10px;

    word-wrap: break-word;
  } */

  .topbar-img-list-3 {
    width: 49%;
    margin-top: 10px;
    padding: 0 15px;
  }

  .list-item-value {
    font-size: 14px;
    margin: 0px 0px 10px 0px;
    font-weight: 800;
    color: #0a0a0a;
  }
  .list-item-title {
    font-size: 13px;
    margin: 0;
    font-weight: 700;
    text-transform: uppercase;
    color: #0a0a0a;
  }
  .topbar-list-items-1-title,
  .topbar-list-items-2-title,
  .topbar-list-items-3-title {
    font-size: 13px;
  }
  .filter-span-text {
    font-size: 13px;
  }
  .purchased-filter-div {
    width: fit-content;
    display: flex;
    color: #0a0a0a;
    /* border: 1px solid #a0a0a0; */
    border-radius: 10px;
    position: relative;
    z-index: 1;
    cursor: pointer;
  }
  .wishlist-searchfield {
    /* width: 48%; */
    position: relative;
    margin-right: 0px;
  }
  .wishlist-product-details {
    display: flex;
    justify-content: center;
    background: #f8f9fc;
    border-radius: 10px;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
  }
  .addtocart {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 15px;
    /* width: 15%; */
  }
  .product-offer-table-list-1 {
    width: 100%;
  }
  .product-offer-table-list-2 {
    width: 100%;
  }
  .topbar-list-items-1-div {
    width: 45%;
  }

  .wishlist-product-offer-table {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }
  .wishlist-product-offer-table-list-1 {
    width: 100%;
  }
  .wishlist-product-offer-table-list-2 {
    width: 100%;
  }
  .wishlist-product-offer-table-list-3 {
    width: 100%;
  }
  .wishlist-store-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: auto;
    padding-right: 10px;
  }
  .product-details {
    display: flex;
    justify-content: center;
    background: #f8f9fc;
    border-radius: 10px;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
  }
  .product-image-title {
    display: flex;
    justify-content: center;
    /* width: 85%; */
    align-items: center;
    flex-wrap: wrap;
  }

  .productbar {
    position: inherit;
    /* top: 0px; */
  }
}
/* deals Count intimation */
.ant-badge {
  background-color: #f90;
  padding: 3px 10px;
  border-radius: 50px;
  /* height: 10px; */
  /* width: 10px; */
  color: #fff;
  text-align: center;
  display: inline-flex;
  margin: 1px 6px;
  font-weight: 600;
}

/* deal download button */

.deal-download {
  margin-left: 10px;
  margin-top: 10px;
  background: white;
  outline: none;
  border: none;
  cursor: pointer;
  color: #0e0e0e;
}
.deal-download svg {
  font-size: 20px;
}

/* market place */
.country-filter {
  width: 180px;
  padding: 10px;
  border-radius: 6px;
  border: #e5e5e5 1px solid;
  outline: none;
  cursor: pointer;
}
.dealsmp-sort-filter {
  width: 180px;
  padding: 10px;
  border-radius: 6px;
  border: #e5e5e5 1px solid;
  outline: none;
  cursor: pointer;
}
/* dealsmp-searchform */
.dealsmp-searchform {
  position: relative;
}
.dealsmp-searchform input {
  width: 240px;

  padding: 10px;
  border-radius: 6px;
  border: #e5e5e5 1px solid;
}
.dealsmp-searchform button {
  position: absolute;
  padding: 8px;
  border-radius: 5px;
  border: #e5e5e5 1px solid;
  right: 0px;
  cursor: pointer;
}
.dealsmp-searchform button svg {
  font-size: 15px;
}
.product-dealmp-containter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* margin: auto; */
  /* background: white;
    border-radius: 10px; */
  margin-top: 10px;
}
.product-dealmp-checkbox {
  /* width: 3%; */
  margin-left: 10px;
}
.showdeal {
  display: block;
}
.hidedeal {
  display: none;
}
.productheader-dealmp-container {
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
  /* width: 85%; */
  width: 92%;
  padding: 20px 0px;
  margin: auto;
  margin-top: 10px;
  align-items: center;
  text-align: center;
}
.productheader-dealmp-container h4 {
  width: 13%;
  text-align: left;
}
.dealsmp-checkbox {
  width: 15px;
  height: 15px;
  cursor: pointer;
}
/* table */
/* .productheader-dealmp-container >h4:nth-child(odd){
  width: 20%;
    text-align: left;
}
.productheader-dealmp-container >h4:nth-child(even){
  width: 20%;
    text-align: left;
}
.h5-productdmp{
  width: 20%;
  text-align: left
}

.h4-productdmp{
  width: 20%;
  text-align: left
}
.h5-productdmp:nth-child(4){
  width: 15% !important;
  text-align: left
} */
.dmp-rm-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dmp-rm-icon {
  margin-right: 10px;
}
.dmp-rm-icon svg {
  font-size: 40px;
  color: #ffd700;
}

/* .sidebuydeal-btn{
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 5px;
  max-width: 120px;
  background: #ff9900;
  border: 1px solid #ff9900;
  color: white;
 
  padding: 6px 10px;
  width: 100%;
  font-weight: 700;
  outline: none;
  border-radius: 6px;
  text-transform: capitalize;
  cursor: pointer;
  transition: 300ms;
  text-decoration: none;
  font-size: 13px;
} */

.sidebuydeal-btn {
  background: #f90;
  border: 1px solid #f90;
  font-weight: 700;
  outline: none;
  border-radius: 6px;
  text-transform: capitalize;
  cursor: pointer;
  color: #fff;
  max-width: 120px;
  width: 100%;
  padding: 6px 10px;
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 5px;
  z-index: 9999;
}

.sideBulkbuydeal-btn {
  background: #f90;
  border: 1px solid #f90;
  font-weight: 700;
  outline: none;
  border-radius: 6px;
  text-transform: capitalize;
  cursor: pointer;
  color: #fff;
  /* max-width: 120px; */
  width: fit-content;
  padding: 6px 10px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-bottom: 0px;
  right: 5%;
  z-index: 9999;
}
.sideBulkbuydeal-btn:hover {
  background: #ffffff;
  border: 1px solid #f90;
  color: #f90;
}
.sidebuydeal-btn:hover {
  background: #ffffff;
  border: 1px solid #f90;
  color: #f90;
  margin:12px 0px 12px 0px;
}
.sideBulkbuydeal-btn > svg {
  font-size: 18px;
  margin: 0px 3px 0px 0px;
}
.sideBulkbuydeal-btn {
  /* display: flex; */
  align-items: center;
}

/* .dis-block{
  display: block;
} */
.vis-hidden {
  visibility: hidden;
}

/* paginationbttns */
.paginationbttns {
  display: flex;
  justify-content: center;
  margin: 10px auto;
  align-items: center;
  padding: 10px;
}
.paginationbttns li {
  list-style: none;
}
.paginationbttns li a {
  list-style: none;
  margin-right: 10px;
  /* padding: 8px 12px; */
  padding: 6px 12px;

  border: #f90 solid 1px;
  background: #f90;
  color: #fff;
  outline: none;
  border-radius: 6px;
}

.paginationbttns li a:hover {
  color: #0a0a0a;
  /* background-color: #fff; */
  /* border: #f90 solid 1px; */
  /* padding: 5px 10px; */
  outline: none;
}
.paginationbttns:focus-visible {
  outline: none;
}

.selected a {
  background-color: #fff !important;
  border: #f90 solid 1px !important;
  color: #f90 !important;
}

.next,
.disabled a {
  cursor: not-allowed;
}
.previous,
.disabled a {
  cursor: not-allowed;
}
.asinlink-icon svg {
  font-size: 14px;
}
/* .topParent{
  position: fixed;
  width: 94%;
  margin: 8px auto 300px auto;
  z-index: 999;
} */
/* .topParent{
  margin-bottom: 287px;
} */
.card-table {
  /* position: fixed; */
  margin: 17px auto 0 32px;
  /* top: 134px;
    width: 89.5%; */
}
/* product box */
.storename-val {
  width: 12%;
  text-align: left;
}
.productname {
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  width: 300px;
}
.category-val {
  width: 12%;
  text-align: left;
}
.bsr-val {
  width: 12%;
  text-align: left;
}
.profit-val {
  width: 12%;
  text-align: left;
}
.roi-val {
  width: 9%;
  text-align: left;
}
.countryname-val {
  width: 12%;
  text-align: center;
}
.date-val {
  width: 12%;
  text-align: center;
}
table {
  font-family: "Poppins", sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  /* border: 1px solid #dddddd; */
  text-align: left;
  padding: 8px;
}

/* td:nth-child(1),
th:nth-child(1) {
  width: 15%;
}
.th:nth-child(2),
td:nth-child(2) {
  width: 15.3%;
}
.th:nth-child(3),
td:nth-child(3) {
  width: 14%;
}
.th:nth-child(4),
td:nth-child(4) {
  width: 10%;
}
.th:nth-child(5),
td:nth-child(5) {
  width: 8.2%;
}

.th:nth-child(6),
td:nth-child(6) {
  width: 13.8%;
}
.th:nth-child(7) {
}  */
td:nth-child(1),
th:nth-child(1) {
  width: 12.5%;
}
.th:nth-child(2),
td:nth-child(2) {
  width: 12.5%;
}
.th:nth-child(3),
td:nth-child(3) {
  width: 12.5%;
}
.th:nth-child(4),
td:nth-child(4) {
  width: 12.5%;
}
.th:nth-child(5),
td:nth-child(5) {
  width: 12.5%;
}

.th:nth-child(6),
td:nth-child(6) {
  width: 12.5%;
}
.th:nth-child(7) {
}
.tabletitle > tr > th:nth-child(1) {
  width: 1.5% !important;
}
.tabletitle > tr > th:nth-child(2) {
  width: 12.5% !important;
}
.tabletitle > tr > th:nth-child(3) {
  width: 12.5% !important;
}
.tabletitle > tr > th:nth-child(4) {
  width: 12.5% !important;
}
.tabletitle > tr > th:nth-child(5) {
  width: 12.5% !important;
}

.tabletitle > tr > th:nth-child(6) {
  width: 12.5% !important;
}
.tabletitle > tr > th:nth-child(7) {
  width: 12.5% !important;
}
.sideBulkdealwrapper {
  position: absolute;
  top: 145px;
  right: 100px;
}
.emptyproducts-purchased {
  margin: 10px 30px;
}
.ProductCard-ScreenFrezze {
  overflow-y: overlay;
  /* height: 60vh; */
  height: 53vh;
}

/* responsive */
@media only screen and (max-width: 1200px) {
  .addcredit-wrapper > h4::after,
  .avalaible-deals-wrapper > h4::after {
    left: 163px !important;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 992px) {
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
  .ProductCard-ScreenFrezze {
    height: 41vh !important;
  }
  .topbar-container {
    width: 98%;
  }
  .topbar-list-1 {
    width: 74% !important;
  }
  .topbar-list-items-1-div {
    width: 47%;
  }
  .card-table {
    margin: 17px auto 0 7px;
    overflow: scroll;
  }
  .productcard-status-wrapper {
    width: 50%;
  }
}
@media only screen and (max-width: 767px) and (min-width: 520px) {
  .ProductCard-ScreenFrezze {
    /* height: 29vh !important; */
    height: auto;
    overflow-y: auto;
  }
  .topbar-list-1 {
    padding: 38px !important;
  }
  .card-table {
    margin: 17px auto 0 7px;
    overflow: scroll;
  }
  .product-heading,
  .productheader-dealmp-container {
    overflow: scroll;
  }
  .product-heading > table,
  .tabletitle {
    font-size: 13px;
  }
  .productcard-status-wrapper {
    width: 50%;
  }

  .bulkdealsPriceicon {
    background-color: #ffffff;
    margin: 0px !important;
    margin-bottom: 20px !important;
  }
  .buydealPrice-icon {
    font-size: 12px;
    justify-content: flex-start;

    /* margin-bottom: 20px !important; */
  }

  .sideBulkdealwrapper {
    /* position: absolute;
    top: 30px;
    right: 103px; */
    z-index: 9999;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    position: fixed;
    bottom: 32px;
    left: 25%;
  }
}
@media only screen and (max-width: 520px) and (min-width: 320px) {
  .ProductCard-ScreenFrezze {
    /* height: 42vh !important; */
    height: auto;
    overflow-y: auto;
  }
  .topbar-list-items-1-div {
    width: 100%;
  }
  .topbar-list-1 {
    padding: 5px 15px;
  }
  .topbar-list-items-1 {
    margin: 5px 0px;
  }
  .dealsmp-searchform input {
    width: 100%;
  }
  .productcard-status-wrapper {
    width: 100%;
  }
  .dealsmp-searchform {
    /* margin: 10px 0px; */
    margin: 0px;
  }
  .topbar-img-list-3 {
    width: 100%;
  }
  .productheader-dealmp-container {
    width: 100%;
    overflow: scroll;
    padding: 7px 0px;
  }
  .card-table {
    overflow: scroll;
    margin: 12px auto 0 7px;
  }
  .product-heading {
    /* overflow: scroll; */
    /* overflow: scroll; */
  }
  .remainingcoins-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .remainingcoins-text > h3 {
    width: 200px;
  }
  .remaining-coin-datas {
    align-self: baseline;
    margin-bottom: 10px;
  }
  .purchased-list-res {
    /* height: 47vh !important; */
    height: 70vh !important;
  }
  .product-heading > table,
  .tabletitle {
    font-size: 12px;
  }
  .buydealPrice-icon {
    font-size: 11px;
    /* margin-bottom: 20px !important; */
    justify-content: flex-start;
  }
  .bulkdealsPriceicon {
    margin: 0px !important;
    margin-bottom: 20px !important;
    background-color: #ffffff;
  }
  .dealsmp-sort-filter {
    display: none;
  }
  .paginationbttns li a {
    font-size: 10px;
    padding: 4px 9px;
  }
  .productcard-wrapper {
    margin-bottom: inherit !important;
  }
  .sideBulkdealwrapper {
    /* position: absolute;
    top: 30px;
    right: 103px; */
    z-index: 9999;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    position: fixed;
    bottom: 32px;
    left: 25%;
  }

  .purchased-table-res {
    font-size: 12px;
  }
}
@media only screen and (max-width: 992px) and (min-width: 320px) {
}

/* header request btn */
.header-request-btn {
  transform: inherit !important;
}
.model-notify-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fdf1df;
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0px 40px 0px;
  color: #ce8600;
}
.model-notify-content>span>svg>path{
  stroke: '#d08800'
}
