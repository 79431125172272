.blog-heading {
  /* text-align: center; */
  text-transform: capitalize;
}
.blog-heading-div {
  display: flex;
  justify-content: center;
}
.blog-para-heading {
  color: #8492a6;
}

.learnmore-wrapper {
  margin: 20px auto;
  /* box-shadow: 2px 2px 2px #d7d7d7; */
  border-radius: 5px;
  display: flex;
  width: 90%;
  flex-wrap: wrap;
  /* background: #edf5ff; */
  align-items: center;
  justify-content: space-between;
}
.learnmore-wrapper-text {
  width: 60%;
}
.learnmore-image {
  width: 320px;
  border-radius: 5px;
}
.learnmore-paragraph {
  margin: 15px 20px;
  font-size: 15px;
  font-weight: 300;
  color: #8492a6;
}
.learnmore-image-div {
  width: 40%;
  padding: 20px;
  text-align: center;
}
.learnmore-btn {
  color: #ff9900;
  text-transform: capitalize;
  margin: 15px 20px;
  display: flex;
  justify-content: space-between;
  width: fit-content;
  align-items: center;
  text-decoration: none;
}
.learnmore-text {
  display: inline-block;
  margin-right: 10px;
}
.learnmore-icon {
  display: inline-block;
  padding-top: 5px;
}

/* coins page */
.addcredit-wrapper {
  width: 33%;
  background-color: #edf5ff;
  border-radius: 6px;
  padding: 10px;
  text-align: center;
  margin: 0 auto;
}
.addcredit-wrapper h2 {
  font-weight: 900;
  font-size: 30px;
  margin-bottom: 15px;
}
.addcredit-wrapper p {
  font-size: 16px;
  font-weight: 200;
  margin-bottom: 15px;
}
.addcredit-wrapper > h4,
.avalaible-deals-wrapper > h4 {
  margin: 0px 0px 18px 0px;
  font-weight: 500;
  position: relative;
}
.addcredit-wrapper > h4::after,
.avalaible-deals-wrapper > h4::after {
  position: absolute;
  /* display: block; */
  content: "";
  width: 80px;
  height: 4px;
  /* bottom: 10px; */
  right: 0;
  left: 102px;
  
  background-color: #f90;
  top: 24px;
  border-radius: 25px;
  z-index: 1;
}

.avalaible-deals-wrapper {
  width: 33%;
  border-radius: 6px;
  background-color: #edf5ff;
  padding: 20px 20px 0px 20px;
  margin: 0 auto;
  text-align: center;
}
.avalaible-deals-wrapper > a  , .addcredit-wrapper>a{
  display: flex;
  justify-content: end;
  align-items: self-start;
  color: #000;
}
.addcredit-wrapper>a{
  visibility: hidden;
}

.Add-Credit {
  padding: 10px;
  background-color: #0e0e0e;
  color: #ffffff;
  font-size: 14px;
  border-radius: 6px;
  border: #0e0e0e solid 1px;
  cursor: pointer;
}
.Add-Credit:hover {
  background-color: #ffffff;
  color: #0e0e0e;
  border: #0e0e0e solid 1px;
}
.Get-a-Deal {
  padding: 10px;
  background-color: #fff;
  color: #0e0e0e;
  font-size: 14px;
  border-radius: 6px;
  border: #fff solid 1px;
  cursor: pointer;
}
.Get-a-Deal:hover {
  background-color: #0e0e0e;
  color: #ffffff;
  font-size: 14px;
  border-radius: 6px;
  border: #0e0e0e solid 1px;
}
.avalaible-deals-wrapper h2 {
  font-size: 30px;
  color: #000;
  margin-bottom: 15px;
}
.avalaible-deals-wrapper p {
  font-size: 16px;
  font-weight: 200;
  color: #000;
  margin-bottom: 15px;
  text-transform: capitalize;
}
.avalaible-deals-wrapper > h4::after {
  left:108px !important;
}

/* _input-range-track.css */
/* .input-range__track {
   
    background:#0e0e0e;

    border-radius:3px;
    cursor: pointer;
    display: block;
    height: 10px;
    position: relative;
    transition: left 200ms ease-out, width 200ms ease-out;
    will-change: left, width;
  
   
  }
  .input-range--disabled  {
    background:  #e6e9ea;
  }
  .input-range__track--background {
    margin-top:1.5px;
    top: 50%;
    position: absolute;
    left: 0;
    right: 0;
  }
  
  .input-range__track--active {
    
    background: #e6e9ea ;

  }
  
 
  .input-range {
    height:1rem;
    position: relative;
   
    width: 80%;
    margin: auto;
    margin-bottom: 10px;
    overflow-x: clip
  }
  .input-range__slider {
   
    appearance: none;
    background: #ff9900;
    border: 1px solid #ff9900;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 1rem;
   
    margin-left: 0;
  margin-top: -0.8rem;

    outline: none;
    position: absolute;
    top: 50%;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    width: 1rem;
  } */

/* .input-range__track */
.input-range {
  width: 100%;
  max-width: 500px;
  margin: 15px auto;
  /* min-width: 300px; */
  min-width: 250px;
}

.input-range__track {
  height: 16px;
}
.input-range__slider {
  appearance: none;
  background: #000;
  border: 1px solid #000;
  transform: rotate(45deg);
  /* margin-top: -0.9rem;
  margin-top: -1.5rem; */
  margin-top: -18px;
  border-radius: 0px;
  /* height: 2rem;  
  width: 9px; */
  height: 20px;
  width: 20px;
  margin-left: -1px;
}
.input-range__track--active {
  background: #f90;
}
.input-range__slider:active {
  transform: scale(1.05);
  transform: rotate(45deg);
}
.credit-title {
  text-align: center;
  padding: 20px 0px;
}
.confirm-btn-container {
    padding: 10px;
    text-align: right;
    width: 100%;
}
.confirm-btn-container>h3{
    text-align: center;
    margin:10px 0px 0px 0px;
}
/* .input-range__slider:nth-child(even){

    
    visibility: hidden;
 }
 .input-range__slider:first-child
 {
    display: block !important;
 } */
 .coin-price-total-wrapper{
    display: flex;
    justify-content: end;
    margin: 40px 0px 20px 0px;
    flex-direction: column;
 }
 .coin-price-total-wrapper>form{
    display: flex;
    justify-content: end;
    align-items: center;
 }
 .buy-nowbtn{
    background: #ff9900;
    border: 1px solid #ff9900;
    color: #000;
    box-shadow: 2px 2px 5px 0px rgb(149 149 149);
 }
 .buy-nowbtn:hover{
  background: #ff9900 !important;
  border: 1px solid #ff9900 !important;
  color: #ffffff !important;
 }

.dollar-input {
    padding: 5px;
    width: 86px;
    outline: none;
    /* border-radius: 6px; */
    border: 2px solid #000000;
    color: #0e0e0e;
    display: block;
    margin: 10px 10px 10px 10px;
}
.input-range__label-container {
  left: -50%;
  position: relative;
  bottom: 25px;
  font-size: 20px;
  color: #f90;
  font-weight: 900;
}
/* .input-range__label-container:nth-child(1){
    display: none;
} */

.input-range__label--min,
.input-range__label--max {
  /* bottom: -40px; */
  top: 70px;
}
.input-range__label--min{
  display: none !important;
}
.coin-slider-container {
  /* display: flex; */
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px;
}
/* .coin-slider-form{
    width: 50%;

} */

/* responsive */

@media only screen and (max-width: 1200px) and (min-width: 992px) {
  .learnmore-image-div {
    width: 45%;
    padding: 20px;
    text-align: center;
  }
  .learnmore-wrapper-text {
    width: 55%;
  }
  .learnmore-image {
    width: 320px;
    border-radius: 5px;
    /* text-align: center; */
  }
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
  .addcredit-wrapper > h4::after,
  .avalaible-deals-wrapper > h4::after{
    display: none;
  }
  .learnmore-image-div {
    width: 100%;
    padding: 20px;
    text-align: center;
  }
  .learnmore-wrapper-text {
    width: 100%;
  }
  .learnmore-image {
    width: 320px;
    border-radius: 5px;
    /* text-align: center; */
  }

  .coin-slider-form {
    width: 100%;
    margin: 20px 0px;
  }
  .confirm-btn-container {
    width: 100%;
    margin: 20px 0px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 520px) {
  .learnmore-image-div {
    width: 100%;
    padding: 20px;
    text-align: center;
  }
  .learnmore-wrapper-text {
    width: 100%;
  }
  .learnmore-image {
    width: 320px;
    border-radius: 5px;
    /* text-align: center; */
  }
  .coin-slider-form {
    width: 100%;
    margin: 20px 0px;
  }
  .confirm-btn-container {
    width: 100%;
    margin: 20px 0px;
  }
  .addcredit-wrapper > h4::after,
.avalaible-deals-wrapper > h4::after{
  display: none;
}
.addcredit-wrapper{
  width: 100%;
}
.avalaible-deals-wrapper{
  width: 100%;
margin-top: 10px;
}
}

@media only screen and (max-width: 520px) and (min-width: 320px) {
  .learnmore-image-div {
    width: 100%;
    padding: 20px;
    text-align: center;
  }
  .learnmore-wrapper-text {
    width: 100%;
  }
  .learnmore-image {
    width: 200px;
    border-radius: 5px;
    /* text-align: center; */
  }
  .learnmore-wrapper {
    width: 95%;
  }

  .coin-slider-form {
    margin: 20px 0px;
    width: 100%;
  }
  .confirm-btn-container {
    width: 100%;
    margin: 20px 0px;
  }
  .addcredit-wrapper > h4::after,
  .avalaible-deals-wrapper > h4::after{
    display: none;
  }
  .addcredit-wrapper{
    width: 100%;
  }
  .avalaible-deals-wrapper{
    width: 100%;
  margin-top: 10px;
  }
}
.coin-error-message {
  color: red;
  margin-top: 10px;
}
/* .{
  left: -34px;
  top: -65px
} */
.input-range__label--max .input-range__label-container{
  
  left: 40% !important;
  /* top: -67px !important; */

}
.productcard-title{
  background-color: #edf5ff;

}